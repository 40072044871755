import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm$productCustoms$te, _vm$productCustoms$te2, _vm$data$touchOrLineI, _vm$data$touchOrLineI2, _vm$data$touchOrLineI3;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-primary tw-mr-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1), _vm._v(" "), _c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('action.confirmationOrder')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary ml-2",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('logout'))
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/logout'
        });
      }
    }
  })], 1), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$t('destination')))]), _vm._v(" "), _c('ShippingInfoArea', {
    attrs: {
      "contact": _vm.complementedContact,
      "no-sync-status-detail": ""
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('touch')) + "\n    ")]), _vm._v(" "), _c('TouchSummary', {
    attrs: {
      "item": _vm.data.touchOrLineItem
    }
  }), _vm._v(" "), !_vm.isDigitalGift ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('destinationAddress')) + "\n      ")]), _vm._v(" "), _vm.data.shippingAddress ? _c('AddressLabel', {
    attrs: {
      "detail": "",
      "rows": "",
      "alert-color": "warning",
      "address": _vm.data.shippingAddress
    }
  }) : _c('div', {
    staticClass: "d-flex align-center"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "24",
      "color": "warning"
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
    staticClass: "text-body-1 warning--text"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('warning.noAddresses')) + "\n        ")])], 1), _vm._v(" "), _c('AddressAlert', {
    staticClass: "mt-2",
    attrs: {
      "address": _vm.data.shippingAddress
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mt-1 text-caption secondary-text--text"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('destinationNotes')) + "\n      ")])] : _vm._e(), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n      " + _vm._s(_vm.isDigitalGift ? _vm.$t('issueDate') : _vm.$t('desiredArrivalDate')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_vm.$date(_vm.data.inhouseDate)) + "\n    ")]), _vm._v(" "), ((_vm$productCustoms$te = _vm.productCustoms.texts) === null || _vm$productCustoms$te === void 0 ? void 0 : _vm$productCustoms$te.length) > 0 || _vm.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('printedText')) + "\n      ")]), _vm._v(" "), ((_vm$productCustoms$te2 = _vm.productCustoms.texts) === null || _vm$productCustoms$te2 === void 0 ? void 0 : _vm$productCustoms$te2.length) > 0 ? _c('TouchTextViewer', {
    attrs: {
      "svg": _vm.data.svg,
      "shipping-targets": [{
        contact: _vm.complementedContact,
        shippingAddress: _vm.data.shippingAddress
      }],
      "qr-url": _vm.data.url,
      "logo": _vm.company.image,
      "customs-texts": _vm.productCustoms.texts
    },
    model: {
      value: _vm.data.customTexts,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "customTexts", $$v);
      },
      expression: "data.customTexts"
    }
  }) : _vm._e(), _vm._v(" "), _vm.productCustoms.url ? _c('LabelText', {
    staticClass: "mt-6",
    attrs: {
      "text": _vm.data.url,
      "label": _vm.productCustoms.url
    }
  }) : _vm._e()] : _vm._e(), _vm._v(" "), _vm.hasGiftProduct ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('giftPage')) + "\n      ")]), _vm._v(" "), _c('GiftPageEditor', {
    attrs: {
      "value": _vm.data.lp,
      "logo": _vm.company.image,
      "shipping-targets": [{
        contact: _vm.complementedContact,
        shippingAddress: _vm.data.shippingAddress
      }],
      "product-title": (_vm$data$touchOrLineI = _vm.data.touchOrLineItem.shopify_product.display_gift_name) !== null && _vm$data$touchOrLineI !== void 0 ? _vm$data$touchOrLineI : _vm.data.touchOrLineItem.shopify_product.variant_title,
      "product-image": (_vm$data$touchOrLineI2 = _vm.data.touchOrLineItem.shopify_product.display_gift_image) !== null && _vm$data$touchOrLineI2 !== void 0 ? _vm$data$touchOrLineI2 : (_vm$data$touchOrLineI3 = _vm.data.touchOrLineItem.shopify_product.images) === null || _vm$data$touchOrLineI3 === void 0 ? void 0 : _vm$data$touchOrLineI3[0]
    }
  })] : _vm._e()], 2), _vm._v(" "), _vm.isDigitalGift && _vm.crmName === 'Salesforce' ? _c('ContentArea', [_c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('shipping.selectWriteWowlink')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm.data.writeWowlink ? [_vm._v("\n        " + _vm._s(_vm.$t('extension.writeWowlink.writeTo', [_vm.crmName])) + "\n\n        "), _c('WriteWowlinkSetting', {
    attrs: {
      "value": _vm.data.writeWowlink,
      "status": _vm.data.writeWowlinkSettingStatus,
      "readonly": ""
    }
  })] : _c('span', {
    staticClass: "disabled-text--text"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('extension.writeWowlink.notWriteTo', [_vm.crmName])) + "\n      ")])], 2)], 1) : _vm._e(), _vm._v(" "), !_vm.canCreateOrder ? _c('ContentArea', {
    staticClass: "d-flex align-center"
  }, [_c('h6', {
    staticClass: "flex-shrink-0"
  }, [_vm._v(_vm._s(_vm.$t('authorizer')))]), _vm._v(" "), _c('Input', {
    staticClass: "ml-6 flex-grow-0",
    attrs: {
      "type": "autocomplete",
      "items": _vm.teamManagers,
      "item-text": "name",
      "item-value": "email"
    },
    model: {
      value: _vm.data.assigned_to,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "assigned_to", $$v);
      },
      expression: "data.assigned_to"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', {
    staticClass: "flex-grow-1 flex-basis-1"
  }, [_c('TextButton', {
    attrs: {
      "value": _vm.$t('action.back'),
      "icon": "mdi-chevron-left"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  })], 1), _vm._v(" "), _vm.canCreateOrder ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary flex-shrink-0",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.sendOrder'))
    },
    on: {
      "click": function click($event) {
        return _vm.createOrder(true);
      }
    }
  }) : _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary flex-shrink-0",
    attrs: {
      "type": "button",
      "disabled": !_vm.data.assigned_to
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCreateOrder'))
    },
    on: {
      "click": function click($event) {
        return _vm.createOrder(false);
      }
    }
  }), _vm._v(" "), _c(VSpacer, {
    staticClass: "flex-basis-1"
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };